import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ThemeProvider } from '@mui/material';
import { setDefaultPreferences } from '@/utils/utils';
import theme from '@/config/theme';
import { ToastContainer, Slide } from 'react-toastify';

const App: React.FunctionComponent<AppProps> = ({ children }) => {
  // Set the default lease option local storage object if it doesn't exist
  useEffect(() => {
    setDefaultPreferences();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <React.Fragment>
          <ToastContainer
            theme="dark"
            position="bottom-left"
            draggable={false}
            autoClose={6000}
            hideProgressBar={true}
            transition={Slide}
          />

          {children}
        </React.Fragment>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

interface AppProps {
  children: React.ReactNode;
}

export default App;
