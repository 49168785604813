import { navigate } from 'gatsby';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { format } from 'date-fns';
import unescape from 'lodash/unescape';
import { siteConfig } from '@/config/site';
import { BreadcrumbsType, LeaseType } from '@/defs/declarations.d';
import {
  WordpressWpBrandAcfBestDeals,
  WordpressWpCar,
  WordpressWpCarConnection,
} from '@/defs/generated-models';

/**
 * Update the current URL with the new variant slug
 * @param newVariantSlug
 * @param isInitial
 */
export const updateUrlWithVariant = (newVariantSlug: string, isInitial: boolean = false) => {
  if (typeof window !== 'undefined') {
    const relativePath = window.location.href.replace(window.location.origin, '').split('?')[0];
    const newUrl = `${relativePath}?variant=${newVariantSlug}`;

    if (isInitial) {
      window.history.replaceState(null, '', newUrl);
    } else {
      window.history.pushState(null, '', newUrl);
    }
  }
};

/**
 * Created a set of breadcrumbs for the breadcrumbs navigation
 * @param crumbs
 */
export const generateBreadcrumbs = (crumbs: BreadcrumbsType[]): BreadcrumbsType[] => {
  return crumbs
    .filter(
      (crumb) =>
        (crumb.crumbLabel !== undefined && crumb.crumbLabel !== null) ||
        crumb.pathname !== undefined
    )
    .map((crumb) => ({ pathname: crumb.pathname, crumbLabel: crumb.crumbLabel }));
};

/**
 * Generate the schema markup for the breadcrumbs
 * @param crumbs
 */
export const generateBreadcrumbsSchema = (crumbs: BreadcrumbsType[]): object | null => {
  if (!crumbs || crumbs.length === 0) {
    return null;
  }

  const validCrumbs = crumbs.filter(
    (crumb) => crumb.pathname && crumb.crumbLabel && crumb.crumbLabel !== 'Undefined'
  );

  const siteUrl = siteConfig.siteUrl;

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    '@id': `${siteUrl}/#breadcrumb`,
    itemListElement: validCrumbs.map((crumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: crumb.crumbLabel,
      item: {
        '@id': `${siteUrl}${crumb.pathname}`,
        name: crumb.crumbLabel,
      },
    })),
  };
};

/**
 * Saves the user lease preferences to local storage
 */
export const setDefaultPreferences = () => {
  // Set the default lease option local storage object if it doesn't exist
  if (isNull(localStorage.getItem('content'))) {
    return localStorage.setItem(
      'content',
      JSON.stringify({
        leaseType: 'business',
        agreementTerms: 48,
        initialRental: 12,
        annualMileage: 8000,
      })
    );
  } else {
    return;
  }
};

/**
 * Scroll smoothly to the target element
 * @param elementId
 * @param block
 */
export const smoothScrollToElement = (
  elementId: string,
  block: ScrollLogicalPosition = 'start'
) => {
  const element = document.getElementById(elementId);

  if (typeof window !== 'undefined' && element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: block, inline: 'nearest' });
      return;
    }, 500);
  }
};

/**
 * Unescape the provided string to handle special characters
 * @param str
 */
export const decodeObjectSpecialCharacters = (str: any) => {
  const obj = unescape(JSON.stringify(str));

  if (!str) {
    return undefined;
  }

  return JSON.parse(obj);
};

/**
 * Get the current month
 */
export const getMonth = () => {
  return format(new Date(), 'MMMM');
};

/**
 * Format the date using the provided format
 * @param date
 * @param dateFormat
 */
export const formatDate = (date: Date, dateFormat: string) => {
  return format(date, dateFormat);
};

/**
 * Get the relative URL from the provided URL
 * @param url
 */
export const getRelativeURL = (url: string) => {
  // remove the :// and split the string by '/'
  const arr = url.replace('://', '').split('/');

  // remove the first element (the domain part)
  arr.shift();

  // join again the split parts and return them with a '/' preceding
  return '/' + arr.join('/');
};

/**
 * Check if the number is odd
 * @param n
 */
export const isOdd = (n: number) => {
  return Math.abs(n % 2) === 1;
};

/**
 * Check if the URL is internal
 * @param url
 */
export const isInternalUrl = (url: string) => {
  return url.includes(siteConfig.domain);
};

/**
 * Filter any duplicated from an array and return only unique values
 * @param value
 * @param index
 * @param self
 */
export const onlyUnique = (value: any, index: number, self: any) => {
  if (value !== null) {
    return self.indexOf(value) === index;
  }
};

/**
 * Apply VAT based on the selected leaseType
 * @param leaseType
 * @param monthlyPrice
 * @param depositPrice
 */
export const getFinalPrice = (
  leaseType: LeaseType | string,
  monthlyPrice: number,
  depositPrice: number
) => {
  const monthlyPriceWithVat = Math.round(
    leaseType === 'personal' ? monthlyPrice * 1.2 : monthlyPrice
  );

  const depositWithVAT = Math.round(leaseType === 'personal' ? depositPrice * 1.2 : depositPrice);

  // Update the variant price
  return {
    monthlyPrice: monthlyPriceWithVat,
    depositPrice: depositWithVAT,
  };
};

/**
 * Update the price when changing the variant
 * @param leaseType
 * @param agreementTerms
 * @param initialRental
 * @param annualMileage
 * @param variant
 * @param addonsTotal
 */
export const calculatePrice = (
  leaseType: LeaseType,
  agreementTerms: number,
  initialRental: number,
  annualMileage: number,
  variant: any,
  addonsTotal: number = 0
) => {
  // Check if one of the meta fields price is zero, indicating price is POA
  const isPoa = variant?.post_meta_fields?.price_24m?.mileage_8k === 0;

  if (!variant) {
    return;
  }

  const term = `price_${agreementTerms}m`;
  const mileage = `mileage_${annualMileage / 1000}k`;
  const priceTable = variant.post_meta_fields[term];
  const addonsFinalPrice = addonsTotal ? addonsTotal : 0;
  const startPrice = priceTable[mileage] + addonsFinalPrice;
  const monthlyPrice = startPrice * ((agreementTerms + 2) / (agreementTerms + initialRental - 1));
  const depositPrice = monthlyPrice * initialRental;

  // Return 0 if the item should be POA
  if (isPoa) {
    return {
      monthlyPrice: 0,
      depositPrice: 0,
    };
  }

  return getFinalPrice(leaseType, monthlyPrice, depositPrice);
};

/**
 * Return the monthly price for a variant without applying VAT
 * @param leaseType
 * @param agreementTerms
 * @param initialRental
 * @param annualMileage
 * @param variant
 */
export const calculatePriceMonthly = (
  leaseType: LeaseType | string | undefined = LeaseType.Personal,
  agreementTerms: number,
  initialRental: number,
  annualMileage: number,
  variant: WordpressWpCar | WordpressWpBrandAcfBestDeals
) => {
  // Check if all the values are zero, meaning the item is POA
  if (
    !variant.post_meta_fields ||
    (isNil(variant.post_meta_fields.price_24m) &&
      isNil(variant.post_meta_fields.price_36m) &&
      isNil(variant.post_meta_fields.price_48m))
  ) {
    return 'Enquire';
  }

  const term = `price_${agreementTerms}m`;
  const mileage = `mileage_${annualMileage / 1000}k`;
  const priceTable = variant.post_meta_fields[term];

  const startPrice = priceTable[mileage];

  const monthlyPrice = startPrice * ((agreementTerms + 2) / (agreementTerms + initialRental - 1));
  const depositPrice = monthlyPrice * initialRental;
  const priceWithVAT = getFinalPrice(leaseType, monthlyPrice, depositPrice);
  return priceWithVAT.monthlyPrice >= 1 ? priceWithVAT.monthlyPrice : 'Enquire';
};

/**
 * Get the index of a variant relative to the position in the variants array by filtering the array
 * @param variantId
 * @param variants
 */
export const getVariantIndex = (variantId: number, variants: WordpressWpCarConnection) => {
  const variant = variants?.edges.filter((variant) => variant.node.wordpress_id === variantId);

  return variant[0].node;
};

/**
 * Capitalize the first letter of a string
 * @param str
 */
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
